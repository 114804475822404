<template>
    <div class="container">
        <!-- 顶部搜索 -->
        <el-card v-if="is_estimate">
            <div class="global-search-box">
                <div class="search-content">
                    <div class="search-item">
                        <el-input :placeholder="shopNamesku == 1 ? '请输入商品名称' : '请输入商品SKU编号'" v-model="goodsName">
                            <template slot="prepend">
                                <el-select v-model="shopNamesku" placeholder="请选择">
                                    <el-option v-for="(item, index) in shopNameSkuList" :key="item.value"
                                        :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-input>
                    </div>
                    <div class="search-item">
                        <label class="search-label">商品类型：</label>
                        <el-select v-model="typeValue" clearable filterable placeholder="全部">
                            <el-option v-for="item in common_info['goods.vr_type.list']" :key="item.key" :label="item.value"
                                :value="item.key">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">商品分类：</label>
                        <el-cascader placeholder="请选择分类" v-model="catValue" :options="catList" filterable clearable
                            :props="{ value: 'id', label: 'label', children: 'children' }">
                        </el-cascader>
                    </div>
                    <div class="search-item">
                        <label class="search-label">品牌筛选：</label>
                        <!-- <el-select v-model="brandValue" filterable clearable placeholder="全部">
                            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select> -->
                        <el-select v-model="brandValue" filterable placeholder="请选择" clearable remote
                            :remote-method="remoteMethoded" @visible-change="templateTagChange">
                            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                            <template>
                                <div class="purpagebutkong"></div>
                                <div class="purpagebut">
                                    <el-pagination :small="true" @current-change="handclassfly" hide-on-single-page
                                        :current-page="brandPage" :page-size="10" layout="prev, next"
                                        :total="brandtotal_entry">
                                    </el-pagination>
                                </div>
                            </template>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">是否兑换：</label>
                        <el-select v-model="exchangedValue" placeholder="全部">
                            <el-option v-for="item in exchangedList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="serach-buttom-button">
                    <el-button type="primary" @click="handleSumbit">搜索</el-button>
                    <el-button @click="resetting">重置条件</el-button>
                    <el-button @click="exportTable">导出报表</el-button>
                </div>
            </div>
        </el-card>
        <!-- 表格数据 -->
        <el-card>
            <el-table :data="tableData" style="width: 100%" ref="multipleTable">
                <el-table-column min-width="100" prop="id" label="ID"></el-table-column>
                <el-table-column min-width="150" prop="cat_name" label="商品分类"></el-table-column>
                <el-table-column label="商品信息" min-width="360">
                    <template slot-scope="scope">
                        <div class="goods_detail d-flex align-items-center">
                            <div class="goods_detail_img" @click.stop="goDetail(scope)">
                                <img :src="scope.row.cover_pic" :onerror="errorImg" />
                            </div>
                            <div class="table_name" @click.stop="goDetail(scope)">
                                {{ scope.row.name }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="售价" min-width="150">
                    <template slot-scope="scope">
                        <div>
                            {{ Number(scope.row.price).toFixed(2) }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="是否兑换" min-width="150">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.exchange > 0 ? '已兑换' : '未兑换' }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column min-width="150" prop="exchange" label="兑换数量"></el-table-column>

                <el-table-column label="所属活动" min-width="150">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.activity.name }}
                        </div>
                    </template>
                </el-table-column>

                <template slot="empty">
                    <div class="empty_data">
                        <p>暂无数据</p>
                    </div>
                </template>
            </el-table>
            <div class="main_pages">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="page" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
                </el-pagination>
            </div>
        </el-card>

        <diy-export-more ref="diyExportMore" dialogTitle="兑换记录导出" dialogExportKeys="goods.exchange.record.export.field.list"
            dialogExportUrl="/goods/exchange_export" :dialogExportIsSearch="true"></diy-export-more>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activity_id: 0,
            company_id: 0,
            user_id: 0,
            shopNamesku: "1", //商品名称或SKU
            goodsName: "", //商品名称
            shopNameSkuList: [{
                label: "商品名称",
                value: "1",
            },
            {
                label: "商品SKU",
                value: "2",
            }],
            typeValue: "", //商品类型
            catValue: "", //商品分类
            catList: [], //商品分类列表
            brandValue: "", //品牌筛选
            brandList: [], //商品品牌列表
            exchangedValue: "1", //是否兑换
            exchangedList: [{ // 兑换数据集
                label: "全部",
                value: "0",
            }, {
                label: "已兑换",
                value: "1",
            }, {
                label: "未兑换",
                value: "2",
            }],
            tableData: [],
            errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
            page: 1,
            pageSize: 10,
            total_entry: 0,
            is_estimate: true,
            brandPage: 1,
            brandtotal_entry: 0,
            brandText: '',
            common_info: {}
        }
    },
    created() {
        const { activity_id, company_id, user_id } = this.$route.query;

        if (activity_id) this.activity_id = activity_id
        if (company_id) this.company_id = company_id
        if (user_id) this.user_id = user_id

        let data = {
            keys: [
                "goods.vr_type.list"
            ],
        };
        this.common.getCommonInfo(this, data, res => {
            this.common_info = res;
        });
        if (user_id) {
            this.is_estimate = false;
        }
    },
    mounted() {
        this.argumentCatlist();
        this.brandlistapi();

        // 获取表格数据
        this.getTableData()
    },
    methods: {
        // 商品详情
        goDetail(scope) {
            let url = scope.row.url;
            if (url) {
                window.open(url, "_blank");
            }
        },
        // 提交搜索
        handleSumbit() {
            this.getTableData()
        },
        // 重置
        resetting() {
            this.goodsName = ""
            this.shopNamesku = "1"
            this.catValue = ""
            this.brandValue = ""
            this.typeValue = ""
            this.exchangedValue = "1"
            this.page = 1
            this.brandPage = 1;
            this.brandText = '';

            this.getTableData()
        },
        // 打开导出报表弹窗
        exportTable() {
            this.$refs.diyExportMore.exportMore = true
        },
        // 整理搜索数据
        extractSearch() {
            let _data = {
                "search": [],
                "exchanged": this.exchangedValue,
                "page": this.page,
                "limit": this.pageSize,
                order: [{
                    "key": "id",
                    "value": "desc"
                }]
            }
            // name Or spu
            if (this.goodsName) {
                _data.search.push(obj = {
                    key: this.shopNamesku == 1 ? "name" : "sku",
                    value: this.goodsName,
                    op: "search",
                });
            }
            // 商品分类
            if (this.catValue && this.catValue.length) {
                _data.search.push({
                    key: "cat_id",
                    value: this.catValue[this.catValue.length - 1],
                    op: "equal",
                });
            }
            // 品牌筛选
            if (this.brandValue) {
                _data.search.push({
                    key: "brand_id",
                    value: this.brandValue,
                    op: "equal",
                });
            }
            // 商品类型
            if (this.typeValue !== "") {
                _data.search.push({
                    key: "vr_type",
                    value: this.typeValue,
                    op: "equal",
                });
            }

            // 活动id and 用户id and 企业id
            if (this.activity_id) _data['activity_id'] = this.activity_id
            if (this.user_id) _data['user_id'] = this.user_id
            if (this.company_id) _data['company_id'] = this.company_id

            return _data
        },
        // 获取商品数据
        getTableData() {
            const requestData = this.extractSearch()
            this.$get(this.$apis.information_detail, requestData).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.list
                    this.total_entry = res.data.total
                } else {
                    this.$message.warning(res.message)
                }
            }).catch(error => {
                this.$message.error(res.message)
            })
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.common.backTop();
            this.getTableData();
        },
        // 获取商品分类
        argumentCatlist() {
            this.$get(this.$apis.catList)
                .then((res) => {
                    if (res.code == 200) {
                        this.catList = this.getTreeData(res.data.list);
                    } else {
                        this.$message.warning(res.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
        getTreeData(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    data[i].children = undefined;
                } else {
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        // 获取品牌
        brandlistapi() {
            let data = {
                page: this.brandPage,
                limit: 10,
                search: [],
            };
            if (this.brandText) {
                data.search.push({
                    key: "name",
                    value: this.brandText,
                    op: "search"
                });
            }
            this.$get(this.$apis.brandList, data)
                .then((res) => {
                    if (res.code == 200) {
                        this.brandList = res.data.list;
                        this.brandtotal_entry = res.data.total;
                    } else {
                        this.common.message(this, res.message);
                    }
                })
                .catch((err) => {
                    this.common.message(this, err.message);
                });
        },


        handclassfly(val) {
            this.brandPage = val;
            this.brandlistapi();
        },
        remoteMethoded(e) {
            this.brandText = e;
            this.brandlistapi();
        },
        templateTagChange(val) {
            if (val === false) {
                this.brandList = []
            } else {
                this.brandText = '';
                this.brandlistapi();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.container {

    .goods_detail_img {
        width: 50px;
        height: 50px;
        margin-right: 5px;
        flex-shrink: 0;
    }

    .goods_detail_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .table_name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        flex: 1;
    }

    .main_pages {
        padding-top: 20px;
        width: 100%;
        text-align: right;
    }

}

.purpagebutkong {
    height: 34px;
}

.purpagebut {
    position: absolute;
    bottom: 0;
    padding: 2px 10px;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    border-top: 1px solid #eee;
    background: #fff;
}

.purpagebut .btn-prev {
    float: left;
    min-width: 32px;
}

.purpagebut .btn-next {
    float: right;
    min-width: 32px;
}
</style>